
 
.dltIcon{
    top: 7px;
    right: 7px;
    border: 1px solid transparent;
    transition: all .2s ease;
    z-index: 2;
    border-radius: 50%;
}
.dltIcon:hover{
    border-color: var(--error-color);
}
.dltIcon svg g path{
    fill: var(--primary-color)
}
.dltIcon:hover svg path{

   fill: var(--error-color);
    
}

.Weak{
    color: rgb(255, 115, 0);
}
.Strong{
    color: green;
}
.img{
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 8px;
    transition: .3s ease;
}
.img:hover{
    border-color: rgb(10, 58, 90);
}