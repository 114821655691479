.container {
  background-color: #f4f4f5;
}
.containerBody {
  margin: 0 auto;
  max-width: 1600px;
  padding-top: 20px;
}
.contentContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 20px;
  gap: 12px 0;
}
.contentContainer .col4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.contentContainer .col8 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
svg {
  vertical-align: bottom;
}




@media (min-width: 768px) {
  .contentContainer .col4 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .contentContainer .col8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }
}


 