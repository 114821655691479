.logoImg {
  max-width: 100%;
  height: 48px;
  /* min-width: 162px; */
  -o-object-fit: contain;
  object-fit: contain;
}
.headerBorder {
  position: sticky;
  top: 0;
  z-index: 12;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
.myAccText {
  margin-bottom: 0;
  font-size: 1.2rem;
}
.darkModeBtn {
  background-color: var(--text-color);
  margin-left: 1rem;
  cursor: pointer;
  border: 1px solid #ddd;
}
.moon {
  --size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  color: #fff;
  transition: all 500ms;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  display: block;
}
.sun {
  --size: 1rem;
  --ray-size: calc(var(--size) * -0.4);
  --offset-orthogonal: calc(var(--size) * 0.65);
  --offset-diagonal: calc(var(--size) * 0.45);
  width: var(--size);
  height: var(--size);
  display: block;
  transition: all 500ms;

  transform: scale(0.75);
  border-radius: 50%;
  color: #ffaa00;
  box-shadow: inset 0 0 0 var(--size),
    calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
    var(--offset-orthogonal) 0 0 var(--ray-size),
    0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
    0 var(--offset-orthogonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0
      var(--ray-size),
    var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
    var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}

/* .loginBtn{
    background: transparent;
    border: 1px solid #ddd;
    width: 100%;
    display: inline-block;
    padding: 12px;
    border-radius: 8px;
    font-size: 1.2rem; 
    cursor: pointer;
    transition: all .3s ease;
}
.loginBtn:hover{
    border-color: var(--primary-color);
} */
