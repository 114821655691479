body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Not Found */
.NotFoundContainer {
  padding: 25px;
  background-color: #f5f5f5;
  position: relative;
}

.NotFoundContainer h3 {
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
}

.NotFoundContainer div {
  width: 485px;
  margin: 0 auto;
}
.NotFoundContainer p {
  font-weight: bold;
  white-space: initial;
}

.NotFoundContainer ul {
  margin: 25px 0;
}

.NotFoundContainer div li {
  list-style-type: disc;
  margin-left: 15px;
}

.NotFoundContainer::before {
  content: '404';
  position: absolute;
  font-size: 160px;
  top: 45px;
  left: 5%;
  opacity: 0.1;
  color: rgb(156, 156, 156);
}
.NotFoundContainer::after {
  content: 'Not Found';
  position: absolute;
  font-size: 140px;
  top: 55px;
  left: 55%;
  opacity: 0.1;
  color: rgb(156, 156, 156);
}

@media only screen and (max-width: 500px) {
  .NotFoundContainer div {
    width: 90%;
    margin: 0 auto;
  }
  .NotFoundContainer::after {
    content: '';
  }
}

@media only screen and (max-width: 1465px) {
  .NotFoundContainer::after {
    content: '';
  }
}
