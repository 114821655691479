.loader {
    max-width: 120px;
    /* margin: 150px  auto 0; */
    -webkit-animation-name: spin;
    -webkit-animation-direction: alternate;
    -webkit-animation-duration: 1350ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-direction: alternate;
    -moz-animation-duration: 1350ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-direction: alternate;
    -ms-animation-duration: 1350ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-direction: alternate;
    animation-duration: 1350ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 50%;
  
    /* unComment for sun behave animation */
    /* height: 100%;
    width: 100%; */
  }
  
  /*  sun behave animation */
  
  /* @keyframes sun {
    0% {
      transform: translate(-5%, 93%);
      opacity: 0;
    }
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      transform: translate(92%, -4%);
      opacity: 0;
    }
  } */
  
  /* spin */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotateY(0deg);
    }
    to {
      -ms-transform: rotateY(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotateY(0deg);
    }
    to {
      -moz-transform: rotateY(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotateY(0deg);
    }
    to {
      -webkit-transform: rotateY(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotateY(0deg);
      opacity: 0;
      /* remove this line to avoid pausing */
      animation: spin;
    }
    to {
      transform: rotateY(360deg);
      opacity: 1;
      
    }
  }
   