.container{
    max-width: 600px;
    margin: 0 auto;
    padding: 40px 20px 20px;
    background-color: white;
    border-radius: 8px;
}
.container .loginForm{
    width: 100%;
    margin-top: 20px;
}