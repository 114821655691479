.container{
    background-color: var(--container-color);
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    min-height: calc(100vh - 69px);
}
.containerBody{
    margin: 0 auto;  
    max-width: 1600px;
    padding-top: 20px;
}
.contentContainer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; 
}
.contentContainer .sidebar{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.contentContainer .mainContent{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
svg{
    
    vertical-align: bottom;
}

.float{
    float: left;
}
.circle{
    background-color: #ddd;;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    color: #fff;
    display: flex;
    margin: 12px 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.circle img {
    max-width: 60px;
}

 
@media (min-width: 1024px){
    .contentContainer .sidebar{
        flex: 0 0 30%;
        max-width: 30%;}
        .contentContainer .mainContent{
            -webkit-box-flex: 0;
            -ms-flex: 0 0 70%;
            flex: 0 0 70%;
            max-width: 70%;}
        }

 