/* hero */

.hero .cover {
  position: relative;
  box-shadow: 0 0 12px #ddd;
}
.cover img {
  max-height: 300px;
  object-fit: contain;
}
.camera {
  transition: opacity 0.3s ease-in;
  opacity: 0;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero .cover:hover .cCamera {
  opacity: 1;
}

.hero .cover:hover .profile {
  z-index: -1;
}

.hero .profile {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 70px;
}
.hero .profile img {
  border-radius: 50%;
  object-fit: contain;
  margin: 0 auto;
  height: 90%;
  width: 90%;
}
.hero .profile .pCamera {
  border-radius: 50%;
}
.hero .profile:hover .pCamera {
  opacity: 1;
}

@media (max-width: 768px) {
  .hero .profile {
    margin-left: 12px;
  }
}
/* end hero */
.dltBanner {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: transparent;
  border: none;
}
.dltBanner svg {
  fill: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
}
.dltBanner svg:hover {
  fill: rgb(163, 1, 1);
}
.addNewBannerBtn svg path {
  fill: var(--primary-color);
}
