/* hero */

.hero .cover {
  position: relative; 
}
.cover img {
  max-height: 300px;
  object-fit: contain;
}
.camera {
  transition: opacity 0.3s ease-in;
  opacity: 0;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero .cover:hover .cCamera {
  opacity: 1;
}

.hero .cover:hover .profile {
  z-index: -1;
}

.hero .profile {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -48px;
  margin-left: 70px;
}
.hero .profile img {
  border-radius: 50%;
  object-fit: contain;
  margin: 0 auto;
  height: 90%;
  width: 90%;
}
.hero .profile .pCamera {
  border-radius: 50%;
}
.hero .profile:hover .pCamera {
  opacity: 1;
}

/* end her */

/* sidebar */
.sideItem {
  border-radius: 8px;
  padding: 9px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  transition: border 0.4s;
  width: 100%;
  cursor: pointer;
  background-color: var(--text-color);
}
.sideItem:hover {
  background-color: #e6dfdf;
}

#tabLinkTxt{
  color: var(--primary-color);
}


 
.icon path {
  fill: var(--primary-color);
  color: var(--primary-color);
}

.active {
  background-color: var(--primary-color);
  color: var(--text-color);
}
.active:hover {
  background-color: var(--primary-color);
}

.active #tabLinkTxt{
  color: var(--text-color);
}

.active .icon path {
  fill: var(--text-color);
  color: var(--text-color);
}

.seletedIcon path {
  fill: var(--text-color);
  color: var(--text-color);
}
.seletedTitlt {
  color: var(--text-color);
}

/* edit product */
.dltIcon {
  top: 7px;
  right: 7px;
  border: 1px solid transparent;
  transition: all 0.2s ease;
}
.dltIcon:hover {
  border-color: var(--error-color);
}
.dltIcon:hover svg path {
  fill: var(--error-color);
}

.Weak {
  color: rgb(255, 115, 0);
}
.Strong {
  color: green;
}

@media (max-width: 1024px) {
  .tabLinksContainer {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 10px;
    align-items: center; 
  }
  .sideItem {
    margin: 0 12px;
  }
}
@media (max-width: 768px) {
 
 
  .hero .profile {
      margin-left: 12px;
  }
}

/* end edit product */
