.otpContainer{
    display: flex;
    justify-content: center;
    padding: 40px 20px 0;
    width: 50%;
    margin: 0 auto;
}
.resendContainer{
    padding-right: 20px;
    width: 50%;
    margin: 31px auto;
}
.resendContainer button{
    border: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    color: rgb(81 78 78)
    
}
.resendContainer button:hover{
    text-decoration: none;
}
.expMsg{
    color: rgb(81 78 78)

}